<template>
  <div
      class="card"
      :class="{ active_card: isActive}"
  >
    <h3>
      {{ label }}
    </h3>
    <p v-if="sublabel.length">
      {{ sublabel }}
    </p>
    <div
      class="d-flex justify-space-between align-center"
    >
      <div
        class="d-flex justify-start align-center controls"
      >
        <div
            class="ml-2 controls__selector"
        >
          <v-select
              v-model="perPageFictionValue"
              :items="perPageOptions"
              @change="reset"
              single-line
          />
        </div>
        <div
            class="controls__pagination"
        >
          <v-pagination
              v-model="curPageValue"
              :length="totalPages"
              :total-visible="visiblePagesNumbers"
          />
        </div>
        <v-switch
            v-if="isHasTabs && tabsListComputed.length === 2"
            v-model="activeTabNameSwitcher"
            :label="`show ${tabsListComputed[1].name}`"
            @change="switchActiveTabName"
        />
      </div>
    </div>

    <v-btn-toggle
        v-if="isHasTabs && tabsListComputed.length > 2"
        v-model="activeTabName"
        group
        mandatory
    >
      <v-btn v-for="tab in tabsListComputed"
         :key="tab.name"
         :value="tab.name"
         size="x-small"
         text
      >
        {{ tab.text }}
      </v-btn>
    </v-btn-toggle>

    <v-data-table
        :headers="mainHeaders"
        :items="normalizedList"
        :page.sync="curPageValue"
        :items-per-page="perPageValue"
        hide-default-footer
        class="elevation-1 range-list"
        :class="{'row-pointer': isMarkActiveItem, 'expanded': activeTabNameSwitcher}"
        @page-count="totalPages = $event"
        @click:row="rowClick"
        :item-class="itemRowBackground"
        :custom-sort="customSort"
    >
      <template v-slot:item.value="{ item }">
        <div class="range-line">
          <span class="value">{{
              isGlobalPercent
                  ? `${item.ratio}%`
                  : $numberManagement.contractInteger(item.value)
            }}</span>
          <span class="bar-holder">
            <span class="bar-fill" :style="`width: ${item.ratio}%;`"/>
          </span>
        </div>
      </template>

      <template
          v-for="(linksHeader, headerNum) in linksHeaders"
          v-slot:[`item.${linksHeader.value}`]="{ item }"
      >
        <v-row
          :key="headerNum"
          no-gutters
        >
          <v-col
            v-for="(linkItem, i) in item.links[linksHeader.value]"
            :key="i"
          >
            <a
                :href="linkItem.uri"
                target="_blank"
            >Link{{i+1}}</a>
          </v-col>
        </v-row>

      </template>

      <template v-for="field in Object.keys($scopedSlots)" v-slot:[field]="{item}">
        <slot :name="field" :item="item"/>
      </template>

      <template
          v-for="numberHeader in numberHeaders"
          v-slot:[`item.${numberHeader.value}`]="{ item }">
        {{
          isGlobalPercent
              ? `${item.values[numberHeader.value].ratio}%`
              : $numberManagement.contractInteger(
                  item.values[numberHeader.value] && item.values[numberHeader.value].value || 0
              )
        }}
      </template>

      <template v-slot:item.arrow="{ }">
        <div class="arrow" />
      </template>
    </v-data-table>
  </div>
</template>

<script>

export default {
  name: 'TablePaginatedToggleButton',
  props: {
    label: {
      type: String,
      default: '',
    },

    sublabel: {
      type: String,
      default: '',
    },

    mainList: {
      type: Array,
    },

    perPageOptions: {
      type: Array,
      default: () => [10, 20, 50, 'All'],
    },

    isGlobalPercent: {
      type: Boolean,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    visiblePagesNumbers: {
      type: Number,
      default: 5,
    },

    isMarkActiveItem: {
      type: Boolean,
      default: false,
    },

    isHasTabs: {
      type: Boolean,
      default: false,
    },

    overrideHeaders: {
      type: Array,
      default: null,
    },

    tabsList: {
      type: Array,
      default: null,
    },

    isShowTotalLabel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      perPageFictionValue: this.perPageOptions[0],
      curPageValue: 1,
      activeItem: 0,
      activeTabName: 'default',
      activeTabNameSwitcher: false,
      totalPages: 0,
    };
  },

  mounted() {
    this.reset();
  },

  methods: {
    reset() {
      this.curPageValue = 1;
      this.activeItem = 0;
    },

    rowClick(item, { index }) {
      this.setActiveItem(index + (this.curPageValue - 1) * this.perPageValue);
    },

    setActiveItem(itemNum) {
      this.activeItem = itemNum;
      this.$emit('setActiveItem', this.activeItem);
    },

    itemRowBackground(item) {
      const elementNumber = item.list_index + (this.curPageValue - 1) * this.perPageValue;

      // eslint-disable-next-line max-len
      if (elementNumber + (this.curPageValue - 1) * this.perPageValue === this.activeItem && this.isMarkActiveItem) {
        return 'is-active';
      }

      return false;
    },

    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (a.label === '<All>') {
          return -1;
        }
        if (b.label === '<All>') {
          return 1;
        }

        if (a.values !== undefined && Object.keys(a.values).includes(index[0])) {
          return isDesc[0]
            ? b.values[index[0]].value - a.values[index[0]].value
            : a.values[index[0]].value - b.values[index[0]].value;
        }

        return isDesc[0]
          ? b[index[0]] - a[index[0]]
          : a[index[0]] - b[index[0]];
      });
      return items;
    },

    switchActiveTabName() {
      this.activeTabName = !this.activeTabNameSwitcher
        ? this.tabsListComputed[0].name
        : this.tabsListComputed[1].name;
    },
  },

  computed: {
    tabsListComputed() {
      return this.tabsList === null
        ? [{ name: 'default', text: 'default' }]
        : this.tabsList;
    },

    defaultHeadersComputed() {
      return [
        {
          text: '', value: 'label', tabs: ['every'],
        },
        {
          text: this.isShowTotalLabel ? 'Total' : '',
          value: 'value',
          width: '150px',
          tabs: ['every'],
        },
      ];
    },

    headers() {
      const headers = this.overrideHeaders === null
        ? this.defaultHeadersComputed
        : this.overrideHeaders;

      headers.push({
        text: '', value: 'arrow', type: 'text', width: '10px', sortable: false, tabs: ['every'],
      });

      return headers;
    },

    linksHeaders() {
      return this.headers.filter((el) => el.type === 'links');
    },

    barHeaders() {
      return this.headers.filter((el) => el.type === 'bar');
    },

    numberHeaders() {
      return this.headers.filter((el) => el.type === 'number');
    },

    mainHeaders() {
      if (!this.isHasTabs) {
        return this.headers;
      }

      return this.headers.filter((el) => el.tabs.includes('every') || el.tabs.includes(this.activeTabName));
    },

    normalizedList() {
      return this.$arrayManagement.normalizedDataArray(this.mainList);
    },

    perPageValue() {
      return this.perPageFictionValue === 'All'
        ? this.mainList.length
        : this.perPageFictionValue;
    },

    listElementClass() {
      return (elementNumber) => {
        const fullClassArray = [];
        if (this.isMarkActiveItem) {
          fullClassArray.push('item-clicker');
        }

        // eslint-disable-next-line max-len
        if (elementNumber + (this.curPageValue - 1) * this.perPageValue === this.activeItem && this.isMarkActiveItem) {
          fullClassArray.push('is-active');
        }

        return fullClassArray.join(' ');
      };
    },
  },

  watch: {
    curPage(newValue) {
      this.curPageValue = newValue;
    },
    perPageFiction(newValue) {
      this.perPageFictionValue = newValue;
    },
  },
};
</script>

<style scoped>
:deep(.is-active) {
  background-color: #e4ede9;
}

:deep(td:has(.arrow)) {
  padding: 0 !important;
  vertical-align: middle;
  text-align: right;
}

:deep(div.arrow) {
  content: '';
  border-left: 32px solid transparent;
  border-bottom: 24px solid transparent;
  border-top: 24px solid transparent;
  height: 0;
  width: 0;
}

:deep(.is-active div.arrow) {
  border-bottom: 24px solid white;
  border-top: 24px solid white;
}

.controls__selector {
  width: 50px;
}

.card {
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
}

.card h3 {
  font-size: 20px;
  margin-bottom: 8px;
}

.card p {
  font-size: 18px;
  color: #bfbfbf;
  margin-bottom: 8px;
}

.item-clicker:hover {
  cursor: pointer;
}

/*range-list*/
.range-list {
  padding-top: 8px;
}

.range-list .v-expansion-panel-header {
  width: 100% !important;
}

.range-sub-line .range-list {
  padding-top: 0;
}

.range-line {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
}

.range-sub-line {
  padding: 1em;
  background: #f0f0f0;
}

.range-list .key {
  flex: 1 1 auto;
}

.range-list.with-dd .key {
  cursor: pointer;
  text-decoration: 2px underline dotted;
}

.range-list.with-dd .range-sub-line .key {
  cursor: default;
  text-decoration: none;
}

.range-list .value {
  padding-right: 0.5em;
}

.range-list .bar-holder {
  display: block;
  height: 1.2em;
  width: 100px;
}

.range-list .bar-fill {
  display: block;
  height: 100%;
  background: #0fb0e1;
  width: 0;
}

.card.active_card {
  background-color: #e4ede9;
}

.row-pointer:deep(tbody tr:hover) {
  cursor: pointer;
}

.expanded:hover:deep(.v-data-table__wrapper) {
  overflow: unset;
  position: relative;
  z-index: 2;
}

.expanded:hover:deep(table) {
  background-color: white;
  border: 1px #1a202c solid;
}
</style>
