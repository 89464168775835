<template>
  <div
      class="card"
      :class="{ active_card: isActive}"
  >
    <h3>
      {{ label }}
    </h3>
    <p v-if="sublabel.length">
      {{ sublabel }}
    </p>
    <div
      class="d-flex justify-space-between align-center"
    >
      <div
        class="d-flex justify-start align-center controls"
      >
        <div
            class="ml-2 controls__selector"
        >
          <v-select
              v-model="perPageFictionValue"
              :items="perPageOptions"
              @change="reset"
              single-line
          />
        </div>
        <div
            class="controls__pagination"
        >
          <v-pagination
              v-model="curPageValue"
              :length="totalPages"
              :total-visible="visiblePagesNumbers"
          />
        </div>
        <v-switch
            v-if="isHasDetails"
            v-model="isShowDetails"
            :label="isShowDetails ? 'hide details' : 'show details'"
        />
      </div>
    </div>
    <v-data-table
        :headers="mainHeaders"
        :items="normalizedList"
        :page.sync="curPageValue"
        :items-per-page="perPageValue"
        hide-default-footer
        class="elevation-1 range-list"
        :class="isMarkActiveItem ? 'row-pointer' : ''"
        @page-count="totalPages = $event"
        @click:row="rowClick"
        :item-class="itemRowBackground"
        :custom-sort="customSort"
    >
      <template v-slot:item.value="{ item }">
        <div class="range-line">
          <span class="value">{{
              isGlobalPercent
                  ? `${item.ratio}%`
                  : $numberManagement.contractInteger(item.value)
            }}</span>
          <span class="bar-holder">
            <span class="bar-fill" :style="`width: ${item.ratio}%;`"/>
          </span>
        </div>
      </template>

      <template
          v-for="detailsHeader in detailsHeaders"
          v-slot:[`item.${detailsHeader.label}`]="{ item }">
        {{
          isGlobalPercent
              ? `${item.values[detailsHeader.label].ratio}%`
              : $numberManagement.contractInteger(item.values[detailsHeader.label].value)
        }}
      </template>

      <template v-slot:item.arrow="{ }">
        <div class="arrow" />
      </template>
    </v-data-table>
  </div>
</template>

<script>

export default {
  name: 'TablePaginated',
  props: {
    label: {
      type: String,
      default: '',
    },

    sublabel: {
      type: String,
      default: '',
    },

    mainList: {
      type: Array,
    },

    perPageOptions: {
      type: Array,
      default: () => [10, 20, 50, 'All'],
    },

    isGlobalPercent: {
      type: Boolean,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    visiblePagesNumbers: {
      type: Number,
      default: 5,
    },

    isMarkActiveItem: {
      type: Boolean,
      default: false,
    },

    defaultExtraHeaders: {
      type: Array,
      default: () => [],
    },

    isHasDetails: {
      type: Boolean,
      default: false,
    },

    detailsHeaders: {
      type: Array,
      default: () => [],
    },

    isShowTotalLabel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      perPageFictionValue: this.perPageOptions[0],
      curPageValue: 1,
      activeItem: 0,
      isShowDetails: false,
      totalPages: 0,
    };
  },

  mounted() {
    this.reset();
  },

  methods: {
    reset() {
      this.curPageValue = 1;
      this.activeItem = 0;
    },

    rowClick(item, { index }) {
      this.setActiveItem(index + (this.curPageValue - 1) * this.perPageValue);
    },

    setActiveItem(itemNum) {
      this.activeItem = itemNum;
      this.$emit('setActiveItem', this.activeItem);
    },

    itemRowBackground(item) {
      const elementNumber = item.list_index + (this.curPageValue - 1) * this.perPageValue;

      // eslint-disable-next-line max-len
      if (elementNumber + (this.curPageValue - 1) * this.perPageValue === this.activeItem && this.isMarkActiveItem) {
        return 'is-active';
      }

      return false;
    },

    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (a.label === '<All>') {
          return -1;
        }
        if (b.label === '<All>') {
          return 1;
        }

        if (a.values !== undefined && Object.keys(a.values).includes(index[0])) {
          return isDesc[0]
            ? b.values[index[0]].value - a.values[index[0]].value
            : a.values[index[0]].value - b.values[index[0]].value;
        }

        return isDesc[0]
          ? b[index[0]] - a[index[0]]
          : a[index[0]] - b[index[0]];
      });
      return items;
    },

  },

  computed: {
    defaultHeaders() {
      return [
        {
          text: '', value: 'label', isShowOnDefault: true, isShowOnDetails: true,
        },
        {
          text: this.isShowTotalLabel ? 'Total' : '',
          value: 'value',
          width: '150px',
          isShowOnDefault: true,
          isShowOnDetails: false,
        },
      ];
    },

    headers() {
      const headers = this.defaultHeaders;

      headers.push(...this.defaultExtraHeaders.map((el) => ({
        text: el.text, value: el.label, isShowOnDefault: true, isShowOnDetails: false,
      })));

      headers.push(...this.detailsHeaders.map((el) => ({
        text: el.text, value: el.label, isShowOnDefault: false, isShowOnDetails: true,
      })));

      headers.push({
        text: '', value: 'arrow', isShowOnDefault: true, isShowOnDetails: true, width: '10px', sortable: false,
      });

      return headers;
    },

    mainHeaders() {
      if (!this.isHasDetails) {
        return this.headers.filter((el) => el.isShowOnDefault);
      }

      return this.isShowDetails
        ? this.headers.filter((el) => el.isShowOnDetails)
        : this.headers.filter((el) => el.isShowOnDefault);
    },

    normalizedList() {
      return this.$arrayManagement.normalizedDataArray(this.mainList);
    },

    perPageValue() {
      return this.perPageFictionValue === 'All'
        ? this.mainList.length
        : this.perPageFictionValue;
    },

    listElementClass() {
      return (elementNumber) => {
        const fullClassArray = [];
        if (this.isMarkActiveItem) {
          fullClassArray.push('item-clicker');
        }

        // eslint-disable-next-line max-len
        if (elementNumber + (this.curPageValue - 1) * this.perPageValue === this.activeItem && this.isMarkActiveItem) {
          fullClassArray.push('is-active');
        }

        return fullClassArray.join(' ');
      };
    },
  },

  watch: {
    curPage(newValue) {
      this.curPageValue = newValue;
    },
    perPageFiction(newValue) {
      this.perPageFictionValue = newValue;
    },
  },
};
</script>

<style scoped>
:deep(.is-active) {
  background-color: #e4ede9;
}

:deep(td:has(.arrow)) {
  padding: 0 !important;
  vertical-align: middle;
  text-align: right;
}

:deep(div.arrow) {
  content: '';
  border-left: 32px solid transparent;
  border-bottom: 24px solid transparent;
  border-top: 24px solid transparent;
  height: 0;
  width: 0;
}

:deep(.is-active div.arrow) {
  border-bottom: 24px solid white;
  border-top: 24px solid white;
}

.controls__selector {
  width: 50px;
}

.card {
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
}

.card h3 {
  font-size: 20px;
  margin-bottom: 8px;
}

.card p {
  font-size: 18px;
  color: #bfbfbf;
  margin-bottom: 8px;
}

.item-clicker:hover {
  cursor: pointer;
}

/*range-list*/
.range-list {
  padding-top: 8px;
}

.range-list .v-expansion-panel-header {
  width: 100% !important;
}

.range-sub-line .range-list {
  padding-top: 0;
}

.range-line {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
}

.range-sub-line {
  padding: 1em;
  background: #f0f0f0;
}

.range-list .key {
  flex: 1 1 auto;
}

.range-list.with-dd .key {
  cursor: pointer;
  text-decoration: 2px underline dotted;
}

.range-list.with-dd .range-sub-line .key {
  cursor: default;
  text-decoration: none;
}

.range-list .value {
  padding-right: 0.5em;
}

.range-list .bar-holder {
  display: block;
  height: 1.2em;
  width: 100px;
}

.range-list .bar-fill {
  display: block;
  height: 100%;
  background: #0fb0e1;
  width: 0;
}

.card.active_card {
  background-color: #e4ede9;
}

:deep(.row-pointer tbody tr:hover) {
  cursor: pointer;
}
</style>
