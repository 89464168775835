<template>
  <TablePaginated
      label="Popular Destinations"
      sublabel="What locations do your passengers view the most?"
      :main-list="itemData"
      :is-global-percent="isGlobalPercent"
      @setActiveItem="setActiveDestination"
      is-mark-active-item
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TablePaginated from '../../../TablePaginated.vue';

export default {
  name: 'PagedListPopularDestinations',
  components: {
    TablePaginated,
  },

  props: {
    itemData: {
      type: [Array],
      default() {
        return ([]);
      },
    },
  },

  methods: {
    ...mapActions({
      setActiveDestination: 'metrics/setActiveDestination',
    }),
  },

  computed: {
    ...mapGetters({
      isGlobalPercent: 'metrics/isGlobalPercent',
    }),
  },
};
</script>
