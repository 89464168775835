<template>
  <TablePaginated
      :label="activeRouteName()"
      :main-list="itemData"
      :is-global-percent="isGlobalPercent"
      @setActiveItem="setActiveFilter"
      is-mark-active-item
      is-active
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TablePaginated from '../../../TablePaginated.vue';

export default {
  name: 'PagedListFilters',
  components: {
    TablePaginated,
  },

  props: {
    itemData: {
      type: [Array],
      default() {
        return ([]);
      },
    },
  },

  methods: {
    ...mapActions({
      setActiveFilter: 'metrics/setActiveFilter',
    }),
  },

  computed: {
    ...mapGetters({
      isGlobalPercent: 'metrics/isGlobalPercent',
      activeRouteName: 'metrics/activeRouteName',
    }),
  },
};
</script>
